import { useQuery } from "@tanstack/react-query";
import React from "react";
import { notify } from "../../Components/Utility/notify";
import { Axios } from "../../services/axiosInstance";

const Deposit = () => {
  // const [currentBal, setCurrentBal] = useState(0);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const amount = e.target.Amount.value;

    const res = await Axios.post("/deposit", {
      amount,
      email,
    });
    if (res.data.acknowledged) {
      notify("Deposit Successfully !!  ");
      refetch();
      e.target.reset();
    }
  };

  const { data, refetch, isLoading } = useQuery(["users"], () =>
    Axios.get("/deposits")
  );
  console.log(data);
  if (isLoading) {
    return "Loading....";
  }

  return (
    <div>
      <h2 className="text-2xl mb-12 text-gray-700 font-bold">
        Deposit To Player Account
      </h2>
      <div className="w-full max-w-md">
        <form onSubmit={handleSubmit} className="bg-white   mb-4">
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="email"
            >
              Player Email
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="email"
              name="email"
              id="email"
              placeholder="user@domain.com"
              required
            />
          </div>

          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="Balance"
            >
              Deposit Amount
            </label>
            <input
              // onChange={(e) =>
              //   e.target.value
              //     ? setCurrentBal(parseInt(e.target.value))
              //     : setCurrentBal(parseInt(0))
              // }
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="number"
              name="Amount"
              id="Amount"
              // max={data?.data?.totalBalance}
              placeholder="10000"
              required
            />
            {/* <p className="text-xs pt-1  ">
              Your Current Balance {currentBal > 0 && "will be "}
              <b
                className={
                  data?.data?.totalBalance - currentBal <= 0
                    ? "text-red-600"
                    : "text-green-600"
                }
              >
                {data?.data?.totalBalance - currentBal}
              </b>
            </p> */}
          </div>

          <div className="flex items-center justify-between">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Make Deposit
            </button>
          </div>
        </form>
      </div>
      <div className="p-3">
        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
              <tr>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Amount</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Email</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Status</div>
                </th>

                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">createdAt</div>
                </th>
              </tr>
            </thead>
            <tbody className="text-sm divide-y divide-gray-100">
              {isLoading
                ? "loading...."
                : data?.data?.deposits?.map((info) => (
                    <tr key={info._id}>
                      <td className="p-2 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="font-medium text-gray-800">
                            {info.amount}
                          </div>
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left flex items-center gap-1 font-semibold">
                          {info.email}
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left font-medium ">
                          <span
                            className={`text-xs rounded p-1 text-white ${
                              info.status === "approved"
                                ? "bg-green-400"
                                : "bg-red-400"
                            }`}
                          >
                            {" "}
                            ({info.status})
                          </span>
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left font-medium ">
                          {info?.createdAt.split("T")[0]}
                        </div>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Deposit;
