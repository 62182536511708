import { useQuery } from "@tanstack/react-query";
import React from "react";
import { Link } from "react-router-dom";

import { serverUrl } from "../../Context/AuthContext";

const UserList = () => {
  const { data, isLoading } = useQuery(["allusers"], async () => {
    const res = await fetch(`${serverUrl}/users`);
    return res.json();
  });

  return (
    <div>
      {" "}
      <section className="antialiased   text-gray-600 h-screen ">
        <div className="w-full mx-auto  ">
          <header className="px-5 py-4 border-b border-gray-100">
            <h2 className="font-semibold text-gray-800">All Users</h2>
          </header>
          <div className="p-3">
            <div className="overflow-x-auto">
              <table className="table-auto w-full">
                <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
                  <tr>
                    <th className="p-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Name</div>
                    </th>
                    <th className="p-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Email</div>
                    </th>
                    <th className="p-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Phone</div>
                    </th>
                    <th className="p-2 whitespace-nowrap">
                      <div className="font-semibold text-left">
                        OpeningBalance
                      </div>
                    </th>
                    <th className="p-2 whitespace-nowrap">
                      <div className="font-semibold text-center">Action</div>
                    </th>
                  </tr>
                </thead>
                <tbody className="text-sm divide-y divide-gray-100">
                  {isLoading
                    ? "Loading...."
                    : data.users.map((user) => (
                        <tr key={user._id}>
                          <td className="p-2 whitespace-nowrap">
                            <Link to={`/dashboard/view/${user.email}`}>
                              <div className="flex items-center">
                                <div className="font-medium text-gray-800">
                                  {user.name}
                                </div>
                              </div>
                            </Link>
                          </td>
                          <td className="p-2 whitespace-nowrap">
                            <div className="text-left flex items-center gap-1 font-semibold">
                              {user.email}
                            </div>
                          </td>
                          <td className="p-2 whitespace-nowrap">
                            <div className="text-left font-medium ">
                              {user.phone}
                            </div>
                          </td>
                          <td className="p-2 whitespace-nowrap">
                            <div className="text-left font-medium ">
                              {user.openingBalance}
                            </div>
                          </td>
                          <td className="p-2 whitespace-nowrap">
                            <Link to={`/dashboard/view/${user.email}`}>
                              <div className="text-sm text-center flex items-center justify-center gap-1">
                                <button className="p-2 border ">
                                  View Agent
                                </button>
                              </div>
                            </Link>
                          </td>
                        </tr>
                      ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default UserList;
