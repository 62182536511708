import React from "react";
import {
  FaChartPie,
  FaListAlt,
  FaMinusCircle,
  FaPlusCircle,
  FaRegUser,
} from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

export const Sidebar = ({ sideBaropen, setSideBaropen }) => {
  const { user } = useAuth();

  const role = user?.role;

  const menuData = [
    {
      path: "/overview/",
      title: "Dashboard",
      permission: ["admin", "agent"],
      icon: (
        <FaChartPie className="w-5 h-5 text-gray-500 group-hover:text-gray-900 transition duration-75" />
      ),
    },
    {
      path: "/pending/",
      title: "Pending",
      permission: ["admin"],
      icon: (
        <FaChartPie className="w-5 h-5 text-gray-500 group-hover:text-gray-900 transition duration-75" />
      ),
    },
    {
      path: "/add-user",
      title: "Add User",
      permission: ["admin"],
      icon: (
        <FaRegUser className="w-5 h-5 text-gray-500 group-hover:text-gray-900 transition duration-75" />
      ),
    },

    {
      path: "/user-list",
      title: "Agent List",
      permission: ["admin"],
      icon: (
        <FaListAlt className="w-5 h-5 text-gray-500 group-hover:text-gray-900 transition duration-75" />
      ),
    },
    {
      path: "/withdraw",
      title: "Withdraw",
      permission: ["admin", "agent"],
      icon: (
        <FaMinusCircle className="w-5 h-5 text-gray-500 group-hover:text-gray-900 transition duration-75" />
      ),
    },
    {
      path: "/deposit",
      title: "Deposit",
      permission: ["admin", "agent"],
      icon: (
        <FaPlusCircle className="w-5 h-5 text-gray-500 group-hover:text-gray-900 transition duration-75" />
      ),
    },
    {
      path: `/view/${user.email}`,
      title: "Profile",
      permission: ["admin", "agent"],
      icon: (
        <FaRegUser className="w-5 h-5 text-gray-500 group-hover:text-gray-900 transition duration-75" />
      ),
    },
  ];
  return (
    <aside
      id="sidebar"
      className={` ${
        sideBaropen ? "block" : "hidden"
      } md:block md:h-screen  flex-col  transition-width duration-75`}
      aria-label="Sidebar"
    >
      <div className="relative md:h-screen flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-white pt-0">
        <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
          <div className="flex-1 px-3 bg-white divide-y space-y-1">
            <ul className=" pb-2">
              {role &&
                menuData.map(
                  (menuitem, idx) =>
                    menuitem?.permission?.includes(role) && (
                      <li
                        onClick={() => setSideBaropen(!sideBaropen)}
                        key={idx}
                      >
                        <NavLink
                          to={`/dashboard${menuitem.path}`}
                          className="text-base mt-5 text-gray-900 font-normal rounded-lg flex items-center p-2 hover:bg-gray-100 group"
                        >
                          {menuitem.icon}
                          <span className="ml-3">{menuitem.title}</span>
                        </NavLink>
                      </li>
                    )
                )}
            </ul>
          </div>
        </div>
      </div>
    </aside>
  );
};
