import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AuthGuard from "./Guards/AuthGuard";
import AuthGuest from "./Guards/AuthGuest";
import Dashboard from "./Layout/Dashboard";
import Main from "./Layout/Main";
import AddUser from "./Pages/Dashboard/AddUser";
import Deposit from "./Pages/Dashboard/Deposit";
import Overview from "./Pages/Dashboard/Overview";
import Pending from "./Pages/Dashboard/Pending";
import UserList from "./Pages/Dashboard/Userlist";
import ViewUser from "./Pages/Dashboard/ViewUser";
import Withdraw from "./Pages/Dashboard/Withdraw";
import Home from "./Pages/Home";
import Login from "./Pages/Login";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />}>
          <Route path="/" element={<Home />} />
          <Route
            path="/login"
            element={
              <AuthGuest>
                <Login />
              </AuthGuest>
            }
          />
        </Route>

        <Route
          path="/dashboard"
          element={
            <AuthGuard>
              <Dashboard />
            </AuthGuard>
          }
        >
          <Route path="/dashboard/overview" element={<Overview />} />
          <Route path="/dashboard/pending" element={<Pending />} />
          <Route path="/dashboard/withdraw" element={<Withdraw />} />
          <Route path="/dashboard/deposit" element={<Deposit />} />
          <Route path="/dashboard/add-user" element={<AddUser />} />
          <Route path="/dashboard/view/:email" element={<ViewUser />} />
          <Route path="/dashboard/user-list" element={<UserList />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
