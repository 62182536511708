import { useQuery } from "@tanstack/react-query";
import { notify } from "../../Components/Utility/notify";
import { Axios } from "../../services/axiosInstance";

const Pending = () => {
  const { data, refetch, isLoading } = useQuery(["pendings"], () =>
    Axios.get("/pending-transactions")
  );
  console.log(data);

  if (isLoading) {
    return "Loading....";
  }

  const approveWithdraw = (id) => {
    Axios.get(`/update-withdraw/${id}`).then((res) => {
      if (res.data.acknowledged) {
        notify("Withdraw Approved !!  ");
        refetch();
      }
    });
  };
  const approveDeposit = (id) => {
    Axios.get(`/update-deposit/${id}`).then((res) => {
      if (res.data.acknowledged) {
        notify("Deposit Approved !!  ");
        refetch();
      }
    });
  };

  return (
    <div>
      {" "}
      <h2 className="font-bold text-gray-700">Pending Deposits</h2>
      <div className="p-3">
        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
              <tr>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Amount</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Email</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Status</div>
                </th>

                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">createdAt</div>
                </th>
              </tr>
            </thead>
            <tbody className="text-sm divide-y divide-gray-100">
              {isLoading
                ? "loading...."
                : data?.data?.allDeposits?.map((info) => (
                    <tr key={info._id}>
                      <td className="p-2 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="font-medium text-gray-800">
                            {info.amount}
                          </div>
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left flex items-center gap-1 font-semibold">
                          {info.email}
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left font-medium ">
                          <span
                            onClick={() => approveDeposit(info._id)}
                            className={`text-xs cursor-pointer rounded p-1 text-white ${
                              info.status === "approved"
                                ? "bg-green-400"
                                : "bg-red-400"
                            }`}
                          >
                            {" "}
                            ({info.status})
                          </span>
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left font-medium ">
                          {info?.createdAt.split("T")[0]}
                        </div>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      </div>
      <h2 className="font-bold text-gray-700">Pending Withdraws</h2>
      <div className="p-3">
        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
              <tr>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Amount</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Email</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Status</div>
                </th>

                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">createdAt</div>
                </th>
              </tr>
            </thead>
            <tbody className="text-sm divide-y divide-gray-100">
              {isLoading
                ? "loading...."
                : data?.data?.allWithdraw?.map((info) => (
                    <tr key={info._id}>
                      <td className="p-2 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="font-medium text-gray-800">
                            {info.amount}
                          </div>
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left flex items-center gap-1 font-semibold">
                          {info.email}
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left font-medium ">
                          <span
                            onClick={() => approveWithdraw(info._id)}
                            className={`text-xs rounded p-1 cursor-pointer text-white ${
                              info.status === "approved"
                                ? "bg-green-400"
                                : "bg-red-400"
                            }`}
                          >
                            {" "}
                            ({info.status})
                          </span>
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left font-medium ">
                          {info?.createdAt.split("T")[0]}
                        </div>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Pending;
