import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { notify } from "../../Components/Utility/notify";
import { Axios } from "../../services/axiosInstance";

const Withdraw = () => {
  const [disable, setDisable] = useState(false);
  const [withdrawId, setWithdrawId] = useState("");
  const sendCode = async (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const amount = e.target.amount.value;

    const res = await Axios.post("/withdraw-hold", {
      amount,
      email,
    });
    if (res.data.acknowledged) {
      setWithdrawId(res.data.insertedId);
      setDisable(true);
      notify("Code Send Successfully !!  ");
    }
  };

  const confirm = async (e) => {
    e.preventDefault();
    const code = e.target.Code.value;
    const res = await Axios.post("/withdraw-confirm", {
      code,
      withdrawId,
    });
    if (res.data.acknowledged) {
      notify("Withdraw Successfully !!  ");
      e.target.reset();
      refetch();
      window.location.reload(false);
    }
  };

  const { data, refetch, isLoading } = useQuery(["withdraws"], () =>
    Axios.get("/withdraws")
  );

  const withdrawList = data?.data?.withdraw;

  return (
    <div>
      <h2 className="text-2xl mb-12 text-gray-700 font-bold">
        Withdraw From Player Account
      </h2>
      <div className="w-full max-w-md">
        <form onSubmit={sendCode} className="bg-white   mb-4">
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="email"
            >
              Player Email
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="email"
              name="email"
              id="email"
              placeholder="user@domain.com"
              disabled={disable}
            />
          </div>

          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="Balance"
            >
              Withdraw Amount
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="number"
              name="amount"
              id="amount"
              placeholder="10000"
              disabled={disable}
            />
          </div>
          <div className="mb-4">
            <button
              className="bg-blue-500 disabled:hidden hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
              disabled={disable}
            >
              Send Verify Code
            </button>
          </div>
        </form>
        {disable && (
          <form onSubmit={confirm}>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="Code"
              >
                Verify Code
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="number"
                name="Code"
                id="Code"
                placeholder="10000"
              />
            </div>

            <div className="flex items-center justify-start gap-5 mb-8">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
              >
                Confirm Withdraw
              </button>
              <button
                onClick={() => window.location.reload(false)}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="btn"
              >
                Cancel
              </button>
            </div>
          </form>
        )}
      </div>
      <div className="overflow-x-auto">
        <table className="table-auto w-full">
          <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
            <tr>
              <th className="p-2 whitespace-nowrap">
                <div className="font-semibold text-left">Amount</div>
              </th>
              <th className="p-2 whitespace-nowrap">
                <div className="font-semibold text-left">Email</div>
              </th>

              <th className="p-2 whitespace-nowrap">
                <div className="font-semibold text-left">Status</div>
              </th>
              <th className="p-2 whitespace-nowrap">
                <div className="font-semibold text-left">createdAt</div>
              </th>
            </tr>
          </thead>
          <tbody className="text-sm divide-y divide-gray-100">
            {isLoading
              ? "loading...."
              : withdrawList?.map((info) => (
                  <tr key={info._id}>
                    <td className="p-2 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="font-medium text-gray-800">
                          {info.amount}
                        </div>
                      </div>
                    </td>
                    <td className="p-2 whitespace-nowrap">
                      <div className="text-left flex items-center gap-1 font-semibold">
                        {info.email}
                      </div>
                    </td>

                    <td className="p-2 whitespace-nowrap">
                      <div className="text-left font-medium ">
                        <span
                          className={`text-xs rounded p-1 text-white ${
                            info.status === "approved"
                              ? "bg-green-400"
                              : "bg-red-400"
                          }`}
                        >
                          {" "}
                          ({info.status})
                        </span>
                      </div>
                    </td>
                    <td className="p-2 whitespace-nowrap">
                      <div className="text-left font-medium ">
                        {info?.createdAt.split("T")[0]}
                      </div>
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Withdraw;
