import React from "react";
import { notify } from "../../Components/Utility/notify";
import { Axios } from "../../services/axiosInstance";

const AddUser = () => {
  return (
    <div>
      <div className=" ">
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            console.log("form submitted");
            const name = e.target.FullName.value;
            const phone = e.target.phone.value;
            const openingBalance = e.target.Balance.value;
            const email = e.target.email.value;
            const password = e.target.password.value;

            //post data to server
            try {
              const res = await Axios.post("/create-user", {
                name,
                phone,
                openingBalance,
                email,
                password,
              });

              if (res.data.acknowledged) {
                notify("Agent Added Successfully !!  ");
                e.target.reset();
                return true;
              }
            } catch (error) {
              if (error.response.status === 401) {
                notify("This Email Already Used!!", "error");
                return false;
              }
            }
          }}
          className="bg-white   mb-4"
        >
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="FullName"
            >
              Full Name
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              name="FullName"
              id="FullName"
              placeholder="Full Name"
              required
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="phone"
            >
              Phone
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="number"
              name="phone"
              id="phone"
              placeholder="01********"
              required
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="Balance"
            >
              Opening Balance
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="number"
              name="Balance"
              id="Balance"
              placeholder="10000"
              required
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="email"
            >
              Email
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="email"
              name="email"
              id="email"
              placeholder="user@domain.com"
              required
            />
          </div>
          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="password"
            >
              Password
            </label>
            <input
              className="shadow appearance-none border   rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
              type="password"
              name="password"
              id="password"
              placeholder="*****"
              required
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Add Agent
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddUser;
